import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from "react-bootstrap";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import sha256 from 'sha256';
import { saveTempId, generateToken, callEncryptionAPI } from '../../store/actions/CommonAction';
import base64 from 'base-64';
import qs from 'query-string';
import { getAccessToken } from '../../utils/utilities';

const Preonboarding = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [clientId, setClientId] = useState('2XDL99O3CG');
    const [consentCreateTimestamp, setConsentCreateTimestamp] = useState('');
    const [timestamp, setTimestamp] = useState('');
    const [txnId, setTxnId] = useState('d52fdf2e-3c6b-4e8c-91f0-91f98e27bb5b');
    const [consentGroupId, setConsentGroupId] = useState('9eead21b-3c2e-4c2c-84a1-a04ffb5a1675');
    const [mob, setMob] = useState('shailendra9.sharma@ril.com');
    const [loading, setLoading] = useState(false); // State to manage loader visibility

    const generateUTCTimestamp = () => {
        return new Date().toISOString();
    };

    useEffect(() => {
        // Set initial timestamps when the component mounts
        const currentTimestamp = generateUTCTimestamp();
        setConsentCreateTimestamp(currentTimestamp);
        setTimestamp(currentTimestamp);

        // Generate token when component mounts
        dispatch(generateToken());
    }, [dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        savedata();
    };

    const savedata = async () => {
        setLoading(true); // Show loader
        const accessToken = getAccessToken();
        if (accessToken) {
            // Use Base64 encoding instead of sha256 for `mob`
            // const mobBase64 = base64.encode(mob);
            const encryptedData = await dispatch(callEncryptionAPI(consentGroupId, clientId, txnId, mob, consentCreateTimestamp, timestamp, accessToken));
            if (encryptedData) {
                //alert("encryptedData->" + encryptedData);
                const base64EncodedData = base64.encode(JSON.stringify(encryptedData));
                //alert("base64EncodedData->" +base64EncodedData);
                //location.href = `http://10.162.6.89:9010/${base64EncodedData}/${clientId}`;
                // location.href = `http://localhost:3000/${base64EncodedData}/${clientId}`;
                //location.href = `http://localhost:3000?encryptedData=${base64EncodedData}&clientId=${clientId}&callback=https://www.jiocinema.com`;
                location.href = `http://10.162.6.89:9010?encryptedData=${base64EncodedData}&clientId=${clientId}&callback=http://10.162.6.89:9111`;
                //location.href = `https://api-jioaa-st.jiolabs.com?encryptedData=${base64EncodedData}&clientId=${clientId}&callback=http://10.162.6.89:9111`;
                // navigate({
                //     pathname: `http://localhost:3000/${base64EncodedData}`,
                //     search: qs.stringify({ data: base64EncodedData })
                // });
            } else {
                console.error('Encryption failed, encryptedData is null');
            }
        } else {
            console.error('Access token not found');
        }
        setLoading(false); // Hide loader
    };

    return (
        <div>
            {loading && (
                <div id="pre-load">
                    <div id="loader" className="loader">
                        <div className="loader-container">
                            <div className="loader-icon">
                                <img
                                    src="Img/loader-icon.png"
                                    alt='loader'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="d-flex justify-content-center">
                <div style={{ width: '400px' }}>
                    <Modal.Header>
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="row text-center">
                                <Modal.Title>Prerequisite</Modal.Title>
                            </div>
                        </div>
                    </Modal.Header>

                    <p style={{ fontSize: '16px', textAlign: 'center', color: 'blue' }} className="mt-3">
                        Kindly enter below details to proceed further.
                    </p>
                    <p style={{ fontSize: '16px', textAlign: 'center', color: 'red' }}>
                        Note: This page only used for demonstration purpose.
                    </p>

                    <form className="p-3 needs-validation was-validated align-self-center">
                        <div>
                            <label className="form-label">Consent GroupId</label>
                            <input
                                className="form-control"
                                placeholder="Enter Consent GroupId"
                                value={consentGroupId}
                                required autoFocus
                                onChange={(e) => setConsentGroupId(e.target.value)}
                            />
                        </div>

                        <div className='mt-2'>
                            <label className="form-label">Client Id</label>
                            <input
                                className="form-control"
                                placeholder="Enter ClientId"
                                value={clientId}
                                required autoFocus
                                onChange={(e) => setClientId(e.target.value)}
                                onKeyPress={(event) => {
                                    if (!/[0-9A-Za-z]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </div>

                        <div className='mt-2'>
                            <label className="form-label">Transaction Id</label>
                            <input
                                className="form-control"
                                placeholder="Enter Transaction Id"
                                value={txnId}
                                required autoFocus
                                onChange={(e) => setTxnId(e.target.value)}
                            />
                        </div>

                        <div className='mt-2'>
                            <label className="form-label">Mobile Number/Email</label>
                            <input
                                className="form-control w-100"
                                placeholder="Enter mobile number"
                                // required maxLength={10}
                                // minLength={10}
                                value={mob}
                                onChange={(e) => setMob(e.target.value)}
                                // onKeyPress={(event) => {
                                //     if (!/^[0-9]+$/.test(event.key)) {
                                //         event.preventDefault();
                                //     }
                                // }}
                                // onPaste={(event) => {
                                //     const pastedText = event.clipboardData.getData('text');
                                //     if (!/^[0-9]+$/.test(pastedText)) {
                                //         event.preventDefault();
                                //     }
                                // }}
                            />
                        </div>

                        <div className='mt-2'>
                            <label className="form-label">Create Consent TimeStamp</label>
                            <input
                                className="form-control"
                                placeholder="Enter Consent TimeStamp"
                                value={consentCreateTimestamp}
                                required autoFocus
                                readOnly
                            />
                        </div>

                        <div className='mt-2'>
                            <label className="form-label">TimeStamp</label>
                            <input
                                className="form-control"
                                placeholder="Enter TimeStamp"
                                value={timestamp}
                                required autoFocus
                                readOnly
                            />
                        </div>

                        <div className="d-flex justify-content-center">
                            <button type='button'
                                className="btn btn-outline-primary btn-sm mt-3 mb-3 d-flex align-self-center"
                                disabled={!(mob && consentGroupId && clientId)}
                                onClick={handleSubmit}
                            >
                                PROCEED
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default Preonboarding;
